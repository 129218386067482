@import url(https://fonts.googleapis.com/css2?Libre+Barcode+39+Text&family=Libre+Barcode+39+Text&family=Comfortaa&family=Libre+Barcode+39+Text&display=swap);
html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Julia Mono';
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    src: url(/static/media/JuliaMono-Regular.c489d5c7.woff2) format('woff2');
  }

:root {
 --background: #fbfff8;
 --text: #111f11;
 font-family: 'Julia Mono';
 height: 100%;
  --font: 'Comfortaa';
 color:#111f11;
 color:var(--text);

}

html,
body{
    font-family: 'Julia Mono';
    font-size:18px;
     color:#111f11;
     color:var(--text);
     background-color: #fbfff8;
     background-color: var(--background);
     width:100vw;
     height:100vh;
}

div{
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size:18px;
    width:100%;
}
/* @media screen and (max-width: 600px) {
  div{
    justify-content: 'flex-start'; 
    justify-items: 'flex-start';
    align-items: 'flex-start';
  }
} */

header{
    border-bottom: 3px dashed #111f11;
    border-bottom: 3px dashed var(--text);
    display: flex;
    justify-content:  space-between;
    align-items:center;
    width:88vw;
    height:88px;
    margin-top: 18px;
  
    /* margin: 33px; */
}


button {
    /* color: orange; */
    font-family: 'Julia Mono';
    color:#111f11;
    color:var(--text);
    background-color:transparent;
    border:none;
    font-size:18px;
    cursor: pointer;
}
a{
    color: #111f11;
    color: var(--text);
    text-decoration:none;
    cursor: pointer;
    align-content: center;
}

li{
  list-style-type: square;
  margin:6px;
}
.purple{
    color: #111f11;
    color: var(--text);
    font-size: 38px;
    font-family: 'Comfortaa';
    font-family: var(--font);
    flex:nowrap 1;
  }

@media screen and (min-width: 800px) {
  .purple{
    font-size: 58px;
  }
}
@media screen and (min-width: 700px) {
  .purple{
    font-size: 48px;
  }
}
@media screen and (max-width: 700px) {
  .purple{
    font-size: 38px;
  }
}
@media screen and (max-width: 500px) {
  .purple{
    font-size: 28px;
  }
}
.container{
    width: 98vw;
    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
    justify-content: flex-start; 
  }

  .pop{
    /* flex-direction: row;
    flex-wrap:wrap; */
    /* height:360px; */
    width:90%;
    object-fit: contain;
    /* margin:8px 8px 8px 8px; */
    border: 1px solid #111f11;
    border: 1px solid var(--text);
    /* border-radius: 3%; */
    padding: 6px;
    cursor:pointer;
  }
  .pop:hover{
    -webkit-transform: scale(1.011);
            transform: scale(1.011);
    transition-duration: .3s;
  }

  .canvas {
    display: flex; 
    flex-direction: row;
    flex-Wrap: wrap;
    justify-content: flex-end;
    
  }
  .row {
    display: flex; 
    flex-direction: row;
    flex-Wrap: wrap;
    max-width: 270px;
    max-height:360px;
    /* margin-top: 33px; */
  
  }

.darkMode {
    --text: #fbfff8;
    --background:#111f11;
    /* background: linear-gradient(to bottom right, #2d27ff,white,  #ff0066); */
    /* background-image: linear-gradient(#d063ff,#ff69eb); */
 }

 .icon{
     font-size: 27px;
     color: #111f11;
     color: var(--text);
     margin-left:9px;


    
 }

 .reverse{
   color: #fbfff8;
   color: var(--background);
   background-color: #111f11;
   background-color: var(--text);
   
 }
 
 .searchbar{
  border-radius: 3%;
  height:21px;
  width: 188px; 
  font-family: 'Courier New', Courier, monospace;
  font-size:16px;
}
input:focus {
  outline-width: 0;
}

::-webkit-input-placeholder{
  color:gray;
  align-items:center;
  /* text-indent: 11px; */
}

.inline{
  flex-direction: row;
  margin-bottom: 1em;
  justify-content: center;;
}
.view {
  width: 50vw;
  object-fit: contain;
  border: 1px solid #111f11;
  border: 1px solid var(--text);
  margin-top:22px;
  overflow-x:auto;
  padding: 6px;
  cursor:pointer; 
  overflow:hidden;
}
/* @media screen and (max-width: 800px) {
  .view{
    width:72vw;
  }
} */

.descript{
  width: 60%; 
  text-align: justify;
  word-wrap:break-word;
  text-justify: inter-word;
  text-align-last:center;
}

.avatar{
  width:66px;
  height:66px;
  border-radius: 60%;
  border: 3px solid #111f11;
  border: 3px solid var(--text);
  padding:3px;  
}

.grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  align-items: flex-start;
  flex-direction:row;
  width: 98vw;
}
.column {
position: l;
  background-clip: padding-box;
align-items:left;
}
.column > * {
  background-clip: padding-box;
  
}

.center{
  display:flex;
  justify-content: center;
}
.text{
  font-family: 'Julia Mono';
  font-size:9px;
  text-align: center;
  width:120px;
  height:120px;
  word-break: keep-all;
  /* display:block; */
   white-space: pre;
  overflow-x: auto;
  overflow-wrap: normal;
  justify-content: center;
  margin:8px 8px 8px 8px;
  border: 1px solid #111f11;
  border: 1px solid var(--text);
  /* border-radius: 3%;  */
  padding: 6px;
  cursor:pointer;
}
.textObjkt{
  font-family: 'Julia Mono';
  font-size:1vw;
  /* text-align: center; */
  width:auto;
  justify-content: center;
  word-break: keep-all;
  /* display:block; */
  white-space: break-spaces;
  overflow-x: auto;
  overflow-wrap: normal;
  padding: 6px;
  cursor:pointer;
}
.text:hover{
  -webkit-transform: scale(1.011);
          transform: scale(1.011);
  transition-duration: .3s;
}

audio::-webkit-media-controls-panel {
     background-color: #fbfff8;
     background-color: var(--background);
}
  
audio::-webkit-media-controls-play-button {
      background-color:  #e8e8e8;
      border-radius: 50%;
}

.formField {
  display:flex;
  margin-bottom: 21px;
  flex-direction: row;
  width:100%;


}

label {
  display: flex;
  margin-bottom: 4px;
}

.formInput {
  display: block;
  width: 88px;
  /* margin:11px; */
  color:#fbfff8;
  color:var(--background);
  background-color: #111f11;
  background-color: var(--text);

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fields{
  display: block;
  width: 100%;
  background-color: #111f11;
  background-color: var(--text);
  color: #fbfff8;
  color: var(--background);
  text-align: center;
}

.form {
  /* display:flex;
  flex-direction: row; */
}

.errorMessage {
  position:absolute;
  color: #ff0000;
  font-size: 14px;
  margin-top: 50px; 
  margin-left: 150px;
}

.formButton{
  border: 1px solid #111f11;
  border: 1px solid var(--text);
  padding:11px;


}
.market{
  width: 93vw;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: flex-start; 
  border: 3px solid #111f11; 
  border: 3px solid var(--text);
  margin-bottom:11px;
}

.objkt{
  margin-top: 85px;
  position: absolute;
  width: 60%;
  border: 2px solid #111f11;
  border: 2px solid var(--text);
  background-color: #fbfff8;
  background-color: var(--background);
  z-index:11;
}

.marketInfo{
  align-items: 'flex-start'; 
  margin: 11px;
  justify-content: space-between;
  flex-direction: row;

}
@media screen and (max-width: 700px) {
  .marketInfo{
    flex-direction: column;
    justify-content: 'flex-start'; 
    justify-items: 'flex-start';
    align-items: 'flex-start';
  }
}

.menubar{
  width: 27px;
  background-color: #111f11;
  background-color: var(--text);
  height: 3px;
  margin-left:18px;
  transition: all 0.2s linear;
}

.loader g{
  width:'33px';
  height: '33px'; 
  fill: #111f11; 
  fill: var(--text);
  stroke: blue;

}
